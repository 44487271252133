import { CalendarGroupService } from '@/class/services/CalendarGroupService'
import { CalendarService } from '@/class/services/CalendarService'
import { LocationService } from '@/class/services/LocationService'
import { UserService } from '@/class/services/UserService'
import Calendar from '@/models/calendar'
import Contact from '@/models/contact'
import Location from '@/models/location'
import User from '@/models/user'
import { IConnection, IUserCalendar } from '@/types/connection'
import Postmate from 'postmate'
import { reactive } from 'vue'

import { CustomFieldService } from '@/class/services/CustomFieldService'
import { TemplateService } from '@/class/services/TemplateService'
import { sortOptions } from '@/utils/generalFunctions'
import moment from 'moment'
import { CalendarLocationSettingsService } from '../class/services/CalendarLocationSettingsService'
import { CalendarResourceService } from '../class/services/CalendarResourceService'
import { CalendarServiceMenuService } from '../class/services/CalendarServiceMenuService'
import { CustomValueService } from '../class/services/CustomValueService'
import CalendarLocationSettings from '../models/calendar_location_settings'
import { CALENDAR_RESOURCE_TYPES } from '../models/calendar_resource'
import ServiceMenu from '../models/service_menu'
declare global {
  interface Window {
    pendo: any
  }
}

const AppState = reactive({
  parentApp: null as Postmate.Model | null,
  filteredContacts: [] as Contact[],
  modalStep: 'select-contact',
  isSaveContactLoading: false,
  selectedContact: undefined as Contact | undefined,
  apiKey: '' as string,
  isUserPartOfLocation: true as boolean,
  isRecurringEnabled: false as boolean,
  disableContactTimezone: false as boolean,
  baseUrl: '' as string,
  embedUrl: '' as string,
  authApiKey: '' as string,
  tokenId: '' as string,
  locationId: '' as string,
  source: '' as string,
  whitelabelDomain: '' as string,
  user: undefined as IUser | undefined,
  companyId: '',
  shouldAllowCustomTime: false,
  locationTimezone: 'UTC',
  locale: '',
  depth: 0,
  globalTeams: [],
  globalRooms: [],
  globalEquipments: [],
  globalCalendars: [],
  globalUsers: [],
  globalServiceMenus: [] as any,
  isLoading: false,
  loggedInUserDetail: null as User | null,
  currentLocationDetail: null as Location | null,
  calendarLocationDetails: null as CalendarLocationSettings | null,
  connections: {} as IConnection,
  userCalendars: [] as IUserCalendar[],
  loadingAssets: {
    calendars: false,
    groups: false,
    users: false,
    editingCalendar: false,
    rooms: false,
    equipments: false,
    serviceMenus: false,
    editingServiceMenu: false,
  },
  editingCalendarId: '',
  editingCalendar: null as Calendar | null,
  editingServiceMenu: null as ServiceMenu | null,
  isAdvancedCalendarPageLoading: false,
  shouldAllowPayment: false,
  errorInDurationPickerMap: {
    slotInterval: false,
    slotDuration: false,
    slotBuffer: false,
    preBuffer: false,
  },
  editCalendarValidationData: {
    haveMerchant: false,
    enableRecurring: false,
    enableGuests: false,
    teamMembers: [],
  },
  changesSaved: false,
  isTimezoneConfigEnabled: false,
  merchant: {} as any,
  shouldAcceptPayment: false,
  isFormFirstEnabled: false,
  isClassicWidgetSelected: false,
  isMultipleLocationEnabled: false,
  mediaFileURI: '',
  isMediaLibraryOpen: false,
  clonedEmailTemplateId: '',
  emailTemplates: undefined as any,
  customFields: undefined as any,
  standardFields: undefined as any,
  opportunityCustomFields: undefined as any,
  customValues: [] as any[],
  nestedCustomValues: [] as any[],
})

export type Option = {
  title: string
  description?: string
  value: string
  enableForHLOnly?: boolean
  isDisabled?: boolean
}

export type MasterOption = {
  title: string
  description?: string
  options?: Option[]
}

export interface IUser {
  id: string
  first_name?: string
  last_name?: string
  profile_photo?: string
  phone?: string
  email?: string
  type: string
  role: string
  permission?: any
  date_update?: string
  version?: number
}

export function initPendo() {
  if (AppState.companyId && AppState.user) {
    const initOptions = {
      visitor: {
        id: AppState.user.id,
      },
      account: {
        id: `${AppState.companyId}::${AppState.locationId}`,
      },
    }
    window.pendo && window.pendo.initialize(initOptions)
  }
}

export const trackPendoEvent = (
  eventName: string,
  eventProperties: { [key: string]: string }
) => {
  if (window.pendo) {
    window.pendo.track(eventName, eventProperties)
  }
}

export const setParentApp = (app: Postmate.Model) => {
  AppState.parentApp = app
}

export function setApiKey(key: string) {
  AppState.apiKey = key
}

export function setIsUserPartOfLocation(key: boolean) {
  AppState.isUserPartOfLocation = key
}

export function setIsRecurringEnabled(key: boolean) {
  AppState.isRecurringEnabled = key
}

export function setIsTimezoneConfigEnabled(value: boolean) {
  AppState.isTimezoneConfigEnabled = value
}

export function setDisableContactTImezone(disableContactTimezone: boolean) {
  AppState.disableContactTimezone = disableContactTimezone
}

export function setBaseUrl(baseUrl: string) {
  AppState.baseUrl = baseUrl
}

export function setEmbedUrl(embedUrl: string) {
  AppState.embedUrl = embedUrl
}

export function setAuthApiKey(key: string) {
  AppState.authApiKey = key
}

export function setCompanyId(id: string) {
  AppState.companyId = id
}

export function setConnections(connections: IConnection) {
  AppState.connections = connections
}

export function setUserCalendars(userCalendars: IUserCalendar[]) {
  AppState.userCalendars = userCalendars
}

export function setTokenId(tokenId: string) {
  AppState.tokenId = tokenId
}

export function setMediaFileURI(uri: string) {
  if (AppState.isMediaLibraryOpen) {
    AppState.mediaFileURI = uri
    AppState.isMediaLibraryOpen = false
  }
}

export function setLocationId(locationId: string) {
  AppState.locationId = locationId
  // console.log('AppState.locationId', AppState.locationId)
  clearGlobalCache()
  // fetchRequiredData()
}

export function setSource(source: string) {
  // console.log('source', source)
  AppState.source = source
}

export function setWhitelabelDomain(whitelabelDomain: string) {
  AppState.whitelabelDomain = whitelabelDomain
}

export function setLocationTimezone(locationTimezone: string) {
  AppState.locationTimezone = locationTimezone
}
// const { t, locale: globalLocale } = useI18n({ useScope: 'global' })

const momentSupportedLocales = ['en_US', 'fr', 'it', 'pt', 'es']

export function setLocale(locale: string) {
  AppState.locale = locale
  if (momentSupportedLocales.includes(locale)) {
    moment.locale(locale)
  } else {
    moment.locale('en')
  }
  localStorage.setItem('locale', locale)
  // globalLocale.value = locale
}

export const setFilteredContacts = (contacts: any[]) => {
  AppState.filteredContacts = contacts
}

export const setGlobalTeams = (teams: any) => {
  AppState.globalTeams = teams
}

export const setGlobalRooms = (rooms: any) => {
  AppState.globalRooms = rooms
}
export const setGlobalEquipments = (equipments: any) => {
  AppState.globalEquipments = equipments
}

export const setLoggedInUserDetail = (loggedInUserDetail: User) => {
  AppState.loggedInUserDetail = loggedInUserDetail
}

export const setCurrentLocationDetail = (currentLocationDetail: Location) => {
  AppState.currentLocationDetail = currentLocationDetail
}

export const setCalendarLocationDetail = (
  calendarLocationDetails: CalendarLocationSettings
) => {
  AppState.calendarLocationDetails = calendarLocationDetails
}

export const setGlobalCalendars = (calendars: any) => {
  AppState.globalCalendars = calendars
}

export const setGlobalUsers = (users: any) => {
  AppState.globalUsers = users
}

export const setGlobalServiceMenus = (serviceMenus: any) => {
  AppState.globalServiceMenus = serviceMenus
}

export const setEditingCalendarId = (calendarId: string) => {
  AppState.editingCalendarId = calendarId
}

export const setEditingCalendar = (calendar: Calendar) => {
  AppState.editingCalendar = calendar
}

export const setEditingServiceMenu = (serviceMenu: any) => {
  AppState.editingServiceMenu = serviceMenu
}

export const setIsAdvancedCalendarPageLoading = (isLoading: boolean) => {
  AppState.isAdvancedCalendarPageLoading = isLoading
}

export const setShouldAllowPayment = (shouldAllowPayment: boolean) => {
  AppState.shouldAllowPayment = shouldAllowPayment
}

export const setErrorInDurationPicker = (
  errorKey: string,
  hasError: boolean
) => {
  AppState.errorInDurationPickerMap[errorKey] = hasError
}

export const setEditCalendarValidationData = (key: string, value: any) => {
  AppState.editCalendarValidationData[key] = value
}

export const resetCalendarEditValidationData = () => {
  AppState.editCalendarValidationData.enableRecurring = false
  AppState.editCalendarValidationData.enableGuests = false
  AppState.editCalendarValidationData.haveMerchant = false
  AppState.editCalendarValidationData.teamMembers = []
}

export function setUser(user: IUser | undefined) {
  AppState.user = {
    id: user?.id || '',
    type: user?.type || '',
    role: user?.role || '',
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
    email: user?.email || '',
    profile_photo: user?.profile_photo || '',
    phone: user?.phone || '',
    permission: user?.permission,
    date_update: user?.date_update || '',
    version: user?.version,
  }
}

export const setIsLoading = (isLoading: boolean) => {
  AppState.isLoading = isLoading
}

export const setSelectedContact = (contact: Contact) => {
  AppState.selectedContact = contact
}

export const toggleChangesSaved = () => {
  AppState.changesSaved = !AppState.changesSaved
}

export const getUsersInLocation = async () => {
  if (!AppState.locationId) {
    return AppState.globalUsers
  }

  AppState.loadingAssets.users = true
  const { data } = await UserService.getAll(AppState.locationId)
  setGlobalUsers(data)
  AppState.loadingAssets.users = false
  return data
}

export const getTeamsInLocation = async () => {
  if (!AppState.locationId) {
    return AppState.globalTeams
  }

  AppState.loadingAssets.groups = true
  const { data } = await CalendarGroupService.getAll(AppState.locationId)
  setGlobalTeams({ teams: data.groups })
  AppState.loadingAssets.groups = false
  return data
}

export const getRoomsInLocation = async () => {
  if (!AppState.locationId) {
    return AppState.globalRooms
  }
  AppState.loadingAssets.rooms = true
  const { data } = await CalendarResourceService.find(
    AppState.locationId,
    CALENDAR_RESOURCE_TYPES.ROOMS
  )
  setGlobalRooms(data)
  AppState.loadingAssets.rooms = false
  return data
}

export const getEquipmentsInLocation = async () => {
  if (!AppState.locationId) {
    return AppState.globalEquipments
  }
  AppState.loadingAssets.equipments = true
  const { data } = await CalendarResourceService.find(
    AppState.locationId,
    CALENDAR_RESOURCE_TYPES.EQUIPMENTS
  )
  setGlobalEquipments(data)
  AppState.loadingAssets.equipments = false
  return data
}

export const getEditingCalendar = async (calendarId: string) => {
  AppState.loadingAssets.editingCalendar = true
  const { data } = await CalendarService.get(calendarId)
  setEditingCalendar(new Calendar(data.calendar))
  AppState.loadingAssets.editingCalendar = false
  return data
}

export const getCustomValues = async () => {
  if (AppState.locationId) {
    if (AppState.customValues?.length) {
      return AppState.customValues
    }
    const { data } = await CustomValueService.getByLocationId(
      AppState.locationId
    )
    AppState.customValues = data.customValues
  }
  return AppState.customValues as any[]
}

export const getNestedCustomValues = async () => {
  if (AppState.locationId) {
    if (AppState.nestedCustomValues?.length) {
      return AppState.nestedCustomValues
    }
    try {
      const { data } = await CustomValueService.getCustomValues(
        AppState.locationId
      )
      AppState.nestedCustomValues = data?.['custom-values'] || []
    } catch (error) {
      console.error('error: fetch custom values ', error)
    }
  }
  return AppState.nestedCustomValues as any[]
}

export const getEditingServiceMenu = async (serviceMenuId: string) => {
  if (serviceMenuId) {
    AppState.loadingAssets.editingServiceMenu = true
    const { data } = await CalendarServiceMenuService.get(serviceMenuId)
    setEditingServiceMenu(new ServiceMenu(data))
    AppState.loadingAssets.editingServiceMenu = false
    return data
  }
}

export const getCalendarsInLocation = async (showThirdPartyCal = false) => {
  if (!AppState.locationId) {
    return AppState.globalCalendars
  }

  AppState.loadingAssets.calendars = true
  const { data } = await CalendarService.getAll(
    AppState.locationId,
    showThirdPartyCal
  )
  setGlobalCalendars(data)
  AppState.loadingAssets.calendars = false
  return data
}

export const clearGlobalCache = () => {
  AppState.globalTeams = null as any
  AppState.globalCalendars = null as any
  AppState.globalUsers = null as any
  AppState.globalRooms = null as any
  AppState.globalEquipments = null as any
  AppState.globalServiceMenus = null
}

export const fetchRequiredData = async (loadInBackground = false) => {
  if (!loadInBackground) {
    setIsLoading(true)
  }
  await Promise.all([
    fetchCalendarLocationSettings(),
    getTeamsInLocation(),
    getUsersInLocation(),
    getCalendarsInLocation(),
    getServiceMenusInLocation(),
    fetchUserDetails(AppState.user?.id, true),
    getRoomsInLocation(),
    getEquipmentsInLocation(),
  ])
  if (!loadInBackground) {
    setIsLoading(false)
  }
}

export const fetchUserDetails = async (
  userId?: string,
  loadInBackground = false
) => {
  if (!userId) {
    return
  }

  if (!loadInBackground) {
    setIsLoading(true)
  }
  const { data } = await UserService.getById(userId)

  setLoggedInUserDetail(new User(data))
  if (!loadInBackground) {
    setIsLoading(false)
  }
}

export const fetchLocationDetails = async (locationId?: string) => {
  if (!locationId) {
    return
  }

  const { data } = await LocationService.getById(locationId)
  setCurrentLocationDetail(new Location(data.location))
}

export const fetchCalendarLocationSettings = async () => {
  if (!AppState.locationId) {
    return
  }

  const { data } = await CalendarLocationSettingsService.getLocationSettings(
    AppState.locationId
  )
  setCalendarLocationDetail(new CalendarLocationSettings(data.locationSettings))
}

export interface ISelectOption {
  label: string | number
  value: string | number | boolean
}

export const getMerchantInLocation = async () => {
  if (AppState.merchant?.merchant_id || !AppState.locationId) {
    return AppState.merchant
  }
  const { data } = await CalendarService.fetchMerchant(AppState.locationId)
  if (data?.length) {
    AppState.merchant = data[0]
  }
  return AppState.merchant
}

export const isServiceCalendarEnabled = () => {
  return AppState?.calendarLocationDetails?.featureFlags?.isServiceMenuEnabled
}

export const isRoomEnabled = () => {
  return AppState?.calendarLocationDetails?.featureFlags?.isRoomEnabled
}

export const isEquipmentEnabled = () => {
  return AppState?.calendarLocationDetails?.featureFlags?.isEquipmentEnabled
}
export function setClonedEmailTemplateId(payload: {
  clonedTemplateId: string
}) {
  AppState.clonedEmailTemplateId = payload.clonedTemplateId
}

export const getServiceMenusInLocation = async () => {
  if (!AppState.locationId) {
    return
  }

  AppState.loadingAssets.serviceMenus = true
  const { data } = await CalendarServiceMenuService.getAll(AppState.locationId)
  setGlobalServiceMenus({ serviceMenus: data.serviceMenus })
  AppState.loadingAssets.serviceMenus = false
  return data
}

export const getLoggedInUserTimezone = () => {
  const locationWiseTimezone =
    AppState.loggedInUserDetail?.locationWiseTimezone || {}
  return locationWiseTimezone[AppState.locationId] || AppState.locationTimezone
}

export const getTargetTimezone = () => {
  return AppState.isTimezoneConfigEnabled
    ? getLoggedInUserTimezone()
    : AppState.locationTimezone
}

export async function getEmailTemplates(reload = false) {
  if (AppState.emailTemplates && !reload) {
    return AppState.emailTemplates
  }
  AppState.emailTemplates = await TemplateService.getEmailTemplates()
  return AppState.emailTemplates
}

export async function fetchCustomFields(reload = false): Promise<any[]> {
  if (AppState.customFields && !reload) {
    return AppState.customFields
  }
  const allFields = await CustomFieldService.getAllFields()
  AppState.customFields = allFields?.filter(field => {
    return field.dataType !== 'STANDARD_FIELD'
  })

  //setting picklistOptionsImage to picklistImageOptions, to maintain the consistency of contacts and opportunities
  AppState.customFields = AppState.customFields?.map(item => {
    if (item.picklistOptions === undefined && item.picklistOptionsImage) {
      item.picklistImageOptions = item.picklistOptionsImage
    }
    return item
  })

  AppState.standardFields = allFields?.filter(field => {
    return field.dataType === 'STANDARD_FIELD'
  })

  // sort custom fields
  if (AppState.customFields) {
    AppState.customFields = sortOptions(AppState.customFields, 'name')
  }

  return AppState.customFields || []
}

export async function fetchOpportunityCustomFields(
  reload = false
): Promise<any> {
  if (AppState.opportunityCustomFields && !reload) {
    return AppState.opportunityCustomFields
  }
  AppState.isLoading = true
  const resp = (await CustomFieldService.getCustomFields(
    AppState.locationId
  )) as any

  AppState.opportunityCustomFields = resp?.customFields
  AppState.isLoading = false

  // sort custom fields
  if (AppState.opportunityCustomFields) {
    AppState.opportunityCustomFields = sortOptions(
      AppState.opportunityCustomFields,
      'name'
    )
  }

  return AppState.opportunityCustomFields || []
}

export default AppState
