import { Plugin } from 'vue'
import UIDatePicker from './UIDatePicker.vue'
import UIModal from './UIModal.vue'
import UITooltip from './UITooltip.vue'

import Icon from './Icon.vue'

export const registerComponents: Plugin = {
  install(app) {
    app.component('UIDatePicker', UIDatePicker)
    app.component('UITooltip', UITooltip)
    app.component('Icon', Icon)
    app.component('UIModal', UIModal)
  },
}
