import AdvancedCalendarSetup from '@/pages/AdvancedCalendarSetup.vue'
import Availability from '@/pages/Availability.vue'
import CalendarPreferences from '@/pages/CalendarPreferences.vue'
import Connections from '@/pages/Connections.vue'
import CalendarSettings from '@/pages/NewCalendarSettings.vue'
import { createRouter, createWebHistory } from 'vue-router'
import ServiceMenuSetup from '../pages/ServiceMenuSetup.vue'

const routes = [
  {
    path: '/v2/location/:locationId/settings/calendars',
    name: 'calendar-settings__default',
    component: CalendarSettings,
  },
  {
    path: '/v2/location/:locationId/settings/calendars/preferences',
    name: 'calendar-settings__preferences',
    component: CalendarPreferences,
  },
  {
    path: '/v2/location/:locationId/settings/calendars/availability',
    name: 'calendar-settings__availability',
    component: Availability,
  },
  {
    path: '/v2/location/:locationId/settings/calendars/connections',
    name: 'calendar-settings__connections',
    component: Connections,
  },
  {
    path: '/calendars-advanced/:calendarId',
    name: 'calendar-settings__advanced',
    component: AdvancedCalendarSetup,
  },
  {
    path: '/service-menu-advanced/:serviceMenuId',
    name: 'service-menu__advanced',
    component: ServiceMenuSetup,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
