<script setup lang="ts">
import AppState from '@/states/app'
import { UIHeader, UITabPane, UITabs } from '@gohighlevel/ghl-ui'
import { computed, ref, watchEffect } from 'vue'
import MyAvailability from '../components/Availability/MyAvailability.vue'
const tabType = ref('my-availability')

const isUserPartOfLocation = computed(() => {
  return !!AppState?.isUserPartOfLocation
})

watchEffect(() => {
  if (!isUserPartOfLocation.value) {
    tabType.value = 'account-availability'
  }
})
</script>

<template>
  <div class="p-8">
    <UIHeader
      id="calendar-availability-settings-headers"
      :title="$t('settings.availability.availability')"
    />
    <UITabs v-model:value="tabType" type="line">
      <UITabPane
        v-if="isUserPartOfLocation"
        name="my-availability"
        :tab="$t('settings.availability.my_availability')"
        class="mt-6"
      >
        <MyAvailability></MyAvailability>
      </UITabPane>
    </UITabs>
  </div>
</template>
