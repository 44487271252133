<script setup lang="ts">
import { CalendarGroupService } from '@/class/services/CalendarGroupService'
import AppState from '@/states/app'
import {
  UIForm,
  UIFormItem,
  UIInput,
  UIInputGroup,
  UIInputGroupLabel,
  UIModal,
  UIModalContent,
  UIModalFooter,
  UIModalHeader,
  UISelect,
  useNotification,
} from '@gohighlevel/ghl-ui'
import { computed, ref, watch } from 'vue'

const emit = defineEmits([
  'closeModal',
  'createdRecord',
  'uploadCSV',
  'update:show',
])

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  editPayload: {
    type: Object,
    default: null,
  },
})

const isSlugValidating = ref(false)

watch(
  () => props.show,
  newVal => {
    saving.value = false
    if (newVal) {
      formValue.value = {
        name: props.editPayload?.name,
        description: props.editPayload?.description,
        slug: props.editPayload?.slug,
        widgetType: props.editPayload
          ? props.editPayload?.widgetType
            ? props.editPayload?.widgetType
            : 'classic'
          : 'neo',
      }
    }
  }
)

const rules = {
  name: {
    required: true,
    message: 'The Calendar Group name is required',
    trigger: ['input', 'blur'],
  },
  description: {
    required: false,
    message: 'The Calendar Group description is required',
    trigger: ['input', 'blur'],
  },
  slug: {
    required: true,
    // trigger: [],
    asyncValidator(rule, value) {
      if (props.editPayload?.slug && value === props.editPayload?.slug) {
        return Promise.resolve()
      }
      return new Promise(async (resolve, reject) => {
        // @todo - add slug validating API and fail validation if not unique
        isSlugValidating.value = true
        try {
          if (!value?.trim()) {
            reject('The Calendar Group slug is required')
          }
          const { data } = await CalendarGroupService.validateSlug(
            AppState.locationId,
            value.trim()
          )
          if (!data?.available) {
            return reject(new Error('This slug is already taken'))
          }
        } catch (err) {
          return reject(err)
        } finally {
          isSlugValidating.value = false
        }

        resolve(true)
      })
    },
  },
}

const formValue = ref({
  name: '',
  description: '',
  slug: '',
  widgetType: 'neo',
})

const saving = ref(false)

async function createCalendarGroup(values) {
  values.description = values.description ?? ''
  try {
    saving.value = true
    if (props.editPayload) {
      await CalendarGroupService.edit(props.editPayload.id, {
        ...values,
        // locationId: AppState.locationId,
      })
    } else {
      await CalendarGroupService.create({
        ...values,
        locationId: AppState.locationId,
      })
    }

    saving.value = false
    emit('createdRecord')
  } catch (err) {
    saving.value = false
    useNotification().error({
      duration: 1.5 * 1000,
      content: 'Something went wrong. Please try again.',
    })
  }
}

const formRef = ref()
function handleValidateClick(e) {
  formRef.value
    .getForm()
    .validate()
    .then(async value => {
      await createCalendarGroup(formValue.value)
      Object.keys(formValue.value).forEach(key => {
        formValue.value[key] = ''
      })
    })
    .catch(e => console.error('error'))
}

watch(
  () => formValue.value.slug,
  () => {
    if (formValue.value.slug) {
      formValue.value.slug = formValue.value.slug
        .replace(/[^a-zA-Z0-9-]/g, '')
        .toLowerCase()
    }
  }
)

const groupTemplateOptions = computed(() => {
  return [
    {
      value: 'neo',
      label: 'Neo',
    },
    {
      value: 'classic',
      label: 'Classic',
    },
  ]
})
</script>

<template>
  <UIModal
    :show="show"
    :width="585"
    @update:show="val => $emit('update:show', val)"
  >
    <template #header>
      <UIModalHeader
        id="calendarGroupCreateModalHeader"
        type="success"
        :title="$t('settings.calendar.add_new_calendar_group')"
        @close="$emit('closeModal')"
      />
    </template>
    <UIForm
      id="calendarGroupCreateForm"
      ref="formRef"
      :model="formValue"
      :rules="rules"
      class="h-full w-full max-w-7xl sm:max-w-7xl"
    >
      <p class="m-0 p-0 text-sm">
        {{ $t('settings.calendar.calendar_group_description') }}
      </p>

      <UIModalContent>
        <p class="w-full border border-solid border-gray-200"></p>

        <div class="mt-6 grid">
          <UIFormItem :label="$t('settings.calendar.group_name')" path="name">
            <UIInput
              id="calendarProviderName"
              v-model="formValue.name"
              :placeholder="$t('settings.calendar.group_name')"
            />
          </UIFormItem>

          <UIFormItem
            :label="$t('settings.calendar.group_description')"
            path="description"
          >
            <UIInput
              id="calendarProviderDescription"
              v-model="formValue.description"
              type="textarea"
              :placeholder="$t('settings.calendar.group_description')"
            />
          </UIFormItem>

          <div class="mb-2">
            <div class="mb-1 flex flex-row">
              {{ $t('settings.calendar.template') }}
            </div>
            <div class="mb-3 text-xs text-gray-500">
              {{ $t('settings.calendar.template_description') }}
            </div>
            <UISelect
              id="group-template"
              v-model:value="formValue.widgetType"
              :options="groupTemplateOptions"
              placeholder="Select template"
            />
          </div>

          <UIFormItem
            :label="$t('settings.calendar.group_url')"
            path="slug"
            :style="{
              marginTop: '20px',
            }"
          >
            <UIInputGroup>
              <UIInputGroupLabel>/widget/groups/</UIInputGroupLabel>
              <UIInput
                id="calendarUrl"
                v-model="formValue.slug"
                :loading="isSlugValidating"
                :placeholder="$t('settings.calendar.15mins')"
              />
            </UIInputGroup>
          </UIFormItem>
        </div>
      </UIModalContent>
    </UIForm>

    <template #footer>
      <UIModalFooter
        id="affiliateCreateModalFooter"
        :positive-text="
          props.editPayload
            ? $t('settings.calendar.save')
            : $t('settings.calendar.create')
        "
        :negative-text="$t('settings.calendar.cancel')"
        :loading="saving"
        :disabled="isSlugValidating || saving"
        @positive-click="handleValidateClick"
        @negative-click="$emit('closeModal')"
      >
      </UIModalFooter>
    </template>
  </UIModal>
</template>
