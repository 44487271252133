import config from '@/config'
import { requests } from './BaseService'

export const CustomValueService = {
  getByLocationId: (locationId: string) =>
    requests().get(
      `${config.calendarApiUrl}/locations/${locationId}/customValues`
    ),
  getCustomValues: (locationId: string) =>
    requests().get(`${config.restApiUrl}/custom-data/conversations`, {
      params: {
        locationId: locationId,
        types: 'custom-values',
      },
    }),
}
