<script setup lang="ts">
import { InfoCircleIcon } from '@gohighlevel/ghl-icons/24/outline'
import { UIModal, UIModalFooter, UIModalHeader } from '@gohighlevel/ghl-ui'

const props = defineProps({
  show: Boolean,
  headerTitle: String,
  primaryButtonText: String,
  disabledConfirm: Boolean,
})

const emit = defineEmits(['onConfirm', 'onModalClose', 'update:show'])

const handleSave = () => {
  emit('onConfirm')
}
const handleCancel = () => {
  emit('onModalClose')
}
</script>

<template>
  <UIModal
    id="generalConfirmationModal"
    :show="show"
    @update:show="val => $emit('update:show', val)"
  >
    <template #header>
      <UIModalHeader
        id="generalConfirmationModalHeader"
        type="primary"
        :title="headerTitle"
        :icon="InfoCircleIcon"
        @close="handleCancel"
      >
      </UIModalHeader>
    </template>

    <slot name="message" />

    <template #footer>
      <UIModalFooter
        id="generalConfirmationModalFooter"
        :positive-text="$t('settings.calendar.save')"
        :negative-text="$t('settings.calendar.cancel')"
        :loading="disabledConfirm"
        :disabled="disabledConfirm"
        @positive-click="handleSave"
        @negative-click="handleCancel"
      />
    </template>
  </UIModal>
</template>
