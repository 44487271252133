<script setup lang="ts">
// This starter template is using Vue 3 <script setup> SFCs
// Check out https://v3.vuejs.org/api/sfc-script-setup.html#sfc-script-setup
import router from '@/router'
import { UIContentWrap } from '@gohighlevel/ghl-ui'
import 'moment/dist/locale/da'
import 'moment/dist/locale/de'
import 'moment/dist/locale/en-gb'
import 'moment/dist/locale/es'
import 'moment/dist/locale/fi'
import 'moment/dist/locale/fr'
import 'moment/dist/locale/fr-ca'
import 'moment/dist/locale/hr'
import 'moment/dist/locale/hu'
import 'moment/dist/locale/it'
import 'moment/dist/locale/nb'
import 'moment/dist/locale/nl'
import 'moment/dist/locale/pt'
import 'moment/dist/locale/pt-br'
import 'moment/dist/locale/sv'

import Postmate from 'postmate'
import { computed, onMounted } from 'vue'
import AppState, {
  fetchCalendarLocationSettings,
  fetchRequiredData,
  getCalendarsInLocation,
  getEquipmentsInLocation,
  getRoomsInLocation,
  getServiceMenusInLocation,
  getTeamsInLocation,
  initPendo,
  setApiKey,
  setAuthApiKey,
  setBaseUrl,
  setCompanyId,
  setDisableContactTImezone,
  setEmbedUrl,
  setIsLoading,
  setIsRecurringEnabled,
  setIsTimezoneConfigEnabled,
  setIsUserPartOfLocation,
  setLocale,
  setLocationId,
  setLocationTimezone,
  setMediaFileURI,
  setParentApp,
  setTokenId,
  setUser,
  setWhitelabelDomain,
  toggleChangesSaved,
} from './states/app'
import { setSentryMeta } from './util/setupSentry'

function openParentConnection() {
  const handshake = new Postmate.Model({
    changeChildAppRoute(route: any) {
      router.push(route)
    },
    setTokenId: (tokenId: string) => {
      setTokenId(tokenId)
    },
    setMediaFile: (file: any) => {
      if (file && file.url) {
        setMediaFileURI(file.url)
      }
    },
    goToCalendarSettingsPage: () => {
      router.push({ name: 'calendar-settings__default' })
    },
    refreshCalendars: async () => {
      setIsLoading(true)
      await fetchCalendarLocationSettings()
      await getCalendarsInLocation()
      await getTeamsInLocation()
      await getServiceMenusInLocation()
      await getRoomsInLocation()
      await getEquipmentsInLocation()
      setIsLoading(false)
    },
    refreshAppointments: () => {
      toggleChangesSaved()
    },
    appointmentList: (data: any) => {
      setLocationId(data.locationId)
      router.push({ name: 'appointments-list' })
    },
  })

  handshake.then((parent: any) => {
    setParentApp(handshake)
    const { model } = parent
    const {
      locationId,
      source,
      tokenId,
      apiKey,
      isUserPartOfLocation,
      authApiKey,
      userId,
      userType,
      userRole,
      timeZone,
      locale,
      whitelabelUrl,
      disableContactTimezone,
      baseUrl,
      embedUrl,
      company,
      recurringEventsEnabled,
      isTimezoneConfigEnabled,
    } = model
    setSentryMeta({
      userId: model.userId,
      companyId: model.company.id,
      locationId: model.locationId,
      locale: model.locale,
    })
    setIsUserPartOfLocation(isUserPartOfLocation)
    setApiKey(apiKey)
    setDisableContactTImezone(disableContactTimezone)
    setBaseUrl(baseUrl)
    setEmbedUrl(embedUrl)
    setCompanyId(company?.id)
    setAuthApiKey(authApiKey)
    setWhitelabelDomain(whitelabelUrl)
    setLocationTimezone(timeZone)
    setLocale(locale)
    setTokenId(tokenId)
    setUser({
      id: userId,
      type: userType,
      role: userRole,
      version: 1,
    })
    setLocationId(locationId)
    setIsRecurringEnabled(recurringEventsEnabled)
    setIsTimezoneConfigEnabled(isTimezoneConfigEnabled)
    try {
      initPendo()
    } catch (error) {
      console.error('Calendar app: Pendo not initialized', error)
    }

    parent.emit('get-loggedin-user')
    parent.emit('get-location')

    if (source === 'calendar-setup') {
      fetchRequiredData(false)
      router.push({ name: 'calendar-settings__default' })
    } else if (source === 'appointments_page') {
      router.push({
        name: 'appointments-list',
        params: { locationId: locationId },
      })
    } else {
      router.push({ name: 'select-contact' })
    }
  })
}

const locale = computed(() => {
  return AppState?.locale?.replace('_', '')
})

onMounted(() => {
  openParentConnection()
})
</script>

<template>
  <div class="bg-gray-25">
    <UIContentWrap :full-screen="true" :locale="locale">
      <router-view></router-view>
    </UIContentWrap>
  </div>
</template>

<style></style>
