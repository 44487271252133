<!-- eslint-disable prettier/prettier -->
<script setup lang="ts">
import AppState, { setIsAdvancedCalendarPageLoading } from '@/states/app'
import { Trash01Icon } from '@gohighlevel/ghl-icons/24/outline'
import {
  UIButton,
  UIDivider,
  UIFormItem,
  UIHeader,
  UIInput,
  UIRadio,
  UISwitch,
  UITextSmRegular,
  UITextXsRegular,
  UIUpload,
} from '@gohighlevel/ghl-ui'
import { computed, reactive, ref, watch, watchEffect } from 'vue'
import { CalendarService } from '../../class/services/CalendarService'
import { CalendarType } from '../../models/calendar'
import WidgetConfigurator from './WidgetConfigurator.vue'

const widgetConfigurator = ref()
const state = reactive({
  isLoading: false,
  showMediaLibrary: false,
  mediaLibraryUsedForKey: '',
  isFormFirstEnabled: false,
  formData: {
    calendarCoverImage: '',
    widgetType: 'default',
    codeBlock: '',
    enableStaffSelection: false,
    showSeatsPerSlot: false,
  },
  fileList: [],
})
const editingCalendar = computed(() => {
  return AppState.editingCalendar
})

defineEmits(['onSave', 'onCancel'])

const methods = {
  async loadData() {
    setIsAdvancedCalendarPageLoading(true)

    state.formData.widgetType = editingCalendar.value?.widgetType
      ? editingCalendar.value?.widgetType
      : 'default'

    state.formData.showSeatsPerSlot = Boolean(
      editingCalendar.value?.showSeatsPerSlot
    )

    state.formData.codeBlock = editingCalendar.value?.codeBlock || ''

    state.formData.enableStaffSelection = Boolean(
      editingCalendar.value?.enableStaffSelection
    )
    state.formData.calendarCoverImage =
      editingCalendar.value?.calendarCoverImage || ''
    const pageOrder = editingCalendar.value?.widgetConfig?.pageOrder?.find(
      (x: any) => x.kind === 'form'
    )
    state.isFormFirstEnabled = pageOrder?.position === 0
    setIsAdvancedCalendarPageLoading(false)
  },
  emitSave() {
    const widgetConfig =
      widgetConfigurator.value.methods.getWidgetConfiguration()
    return {
      ...state.formData,
      widgetConfig: widgetConfig,
    }
  },
  async handleLogoUpload(file: any) {
    const formData = new FormData()
    formData.append('logo', file.file.file)
    const {
      data: { uploadedUrl },
    } = await CalendarService.uploadCalendarLogo(
      editingCalendar.value!.id,
      formData
    )

    state.formData.calendarCoverImage = uploadedUrl
  },
  async removeLogo() {
    state.formData.calendarCoverImage = ''
  },
  toggleMediaLibrary() {
    AppState.parentApp?.then(parent => {
      parent.emit('media-library-open', {
        apiKey: '',
        tokenId: AppState.tokenId,
        companyId: AppState.companyId,
        altId: AppState.locationId,
        altType: 'location',
        hideHeader: true,
        allowedExtensions: ['image'],
        maxUploadSize: 50000000,
      })
      AppState.isMediaLibraryOpen = true
    })
  },
}

watchEffect(() => {
  if (AppState.mediaFileURI) {
    state.formData.calendarCoverImage = AppState.mediaFileURI
  }
})

defineExpose({
  methods,
})

watch(
  () => state.formData.widgetType,
  () => {
    state.formData.enableStaffSelection =
      state.formData.widgetType === 'classic'
        ? false
        : state.formData.enableStaffSelection
    AppState.isClassicWidgetSelected = state.formData.widgetType === 'classic'
  }
)

watch(
  () => editingCalendar.value,
  () => {
    methods.loadData()
  },
  { immediate: true }
)

const isServiceCalendar = computed(() => {
  return editingCalendar.value?.isServiceCalendar
})

const showStaffSelection = computed(() => {
  return (
    editingCalendar.value?.isRoundRobinCalendar ||
    editingCalendar.value?.isServiceCalendar
  )
})
</script>

<template>
  <UIHeader
    id="text-only-header"
    :secondary-title="$t('calendar_advanced.extras.extras')"
    :description="
      isServiceCalendar
        ? $t('calendar_advanced.extras.service_additional_preferences')
        : $t('calendar_advanced.extras.extras_sub')
    "
  >
  </UIHeader>
  <UIDivider />

  <div>
    <div class="flex flex-col">
      <div class="flex flex-row">
        {{
          isServiceCalendar
            ? $t('calendar_advanced.extras.service_image')
            : $t('calendar_advanced.extras.calendar_image')
        }}
      </div>
      <div class="mb-3 mt-1 text-xs text-gray-500">
        {{
          isServiceCalendar
            ? $t('calendar_advanced.extras.service_image_description')
            : $t('calendar_advanced.extras.calendar_image_info')
        }}
      </div>
      <div class="flex flex-col gap-1">
        <div class="flex flex-row">
          <UIUpload
            v-if="!state.formData.calendarCoverImage"
            :file-list="state.fileList"
            :multiple="false"
            accept=".svg,.png,.jpg,.gif"
            class="ui-upload-container"
            @change="methods.handleLogoUpload"
          >
            <template #extra>
              <UITextSmRegular>
                <span class="font-semibold text-blue-600">
                  {{ $t('calendar_advanced.meeting_details.click_to_upload') }}
                </span>
                {{ $t('calendar_advanced.meeting_details.or_drag_and_drop') }}
              </UITextSmRegular>
              <UITextXsRegular>
                PNG, JPEG, JPG or GIF (max. dimensions 180x180px | max. size
                2.5mb)
              </UITextXsRegular>
            </template>
          </UIUpload>
          <div v-else class="mt-3 flex items-center space-x-10">
            <img
              :src="state.formData.calendarCoverImage"
              class="h-40 w-36 rounded-lg object-cover"
            />

            <div class="justify-centre flex flex-row">
              <UIButton
                id="calendar-logo-remove-btn"
                class="border-red-300 text-red-700"
                @click="methods.removeLogo"
              >
                <Trash01Icon class="mr-2 w-5"></Trash01Icon>
                <div class="">
                  {{ $t('calendar_advanced.extras.remove_image') }}
                </div>
              </UIButton>
            </div>
          </div>
        </div>
        <p
          v-if="!state.formData.calendarCoverImage"
          class="cursor-pointer text-sm font-medium text-primary-700"
          @click="methods.toggleMediaLibrary"
        >
          {{ $t('calendar_advanced.extras.get_image_from_unsplash')
          }}<span> "{{ $t('calendar_advanced.extras.unsplash') }}"</span>
        </p>
      </div>
    </div>
    <UIDivider />
  </div>

  <div v-if="!isServiceCalendar">
    <div class="mb-2">
      {{ $t('calendar_advanced.extras.calendar_widget_style') }}
      <div class="text-xs font-light leading-6 text-gray-600">
        {{ $t('calendar_advanced.extras.calendar_widget_style_desc') }}
      </div>
    </div>
    <div class="flex w-full flex-col space-y-1">
      <div class="flex w-full flex-col space-y-4">
        <div class="flex space-x-4">
          <UIRadio
            id="calendar-default-widget"
            :checked="state.formData.widgetType === 'default'"
            value="default"
            name="default"
            @change="state.formData.widgetType = 'default'"
          >
            {{ $t('calendar_advanced.extras.neo') }}
          </UIRadio>
          <UITooltip
            v-if="
              (AppState.isFormFirstEnabled ||
                AppState.isMultipleLocationEnabled) &&
              editingCalendar?.calendarType !== CalendarType.CLASS_BOOKING
            "
            :title="
              AppState?.isMultipleLocationEnabled
                ? $t(
                    'calendar_advanced.extras.widget_multiple_location_disabled_tooltip'
                  )
                : $t('calendar_advanced.extras.widget_re_order_tooltip')
            "
            placement="right"
          >
            <UIRadio
              id="calendar-classic-widget"
              :checked="state.formData.widgetType === 'classic'"
              value="classic"
              name="classic"
              :disabled="
                AppState.isFormFirstEnabled ||
                AppState.isMultipleLocationEnabled
              "
            >
              {{ $t('calendar_advanced.extras.classic') }}
            </UIRadio>
          </UITooltip>
          <UIRadio
            v-else
            id="calendar-classic-widget"
            :checked="state.formData.widgetType === 'classic'"
            value="classic"
            name="classic"
            :disabled="
              editingCalendar?.calendarType === CalendarType.CLASS_BOOKING ||
              AppState.isFormFirstEnabled
            "
            @change="state.formData.widgetType = 'classic'"
          >
            {{ $t('calendar_advanced.extras.classic') }}
          </UIRadio>
        </div>
      </div>
    </div>
  </div>

  <WidgetConfigurator ref="widgetConfigurator" />

  <UIFormItem
    :label="$t('calendar_advanced.extras.insert_custom_code')"
    class="mt-5 w-96"
  >
    <div class="flex w-full flex-col space-y-4">
      <UIInput
        id="calendar-custom-codeBlock"
        v-model="state.formData.codeBlock"
        :rows="4"
        type="textarea"
        :placeholder="$t('calendar_advanced.extras.custom_code_placeholder')"
      />

      <div v-if="state.formData.codeBlock" class="text-yellow-500">
        {{ $t('calendar_advanced.extras.code_block_tooltip') }}
      </div>
    </div>
  </UIFormItem>

  <UIDivider />

  <div v-if="editingCalendar?.isClassBookingCalendar" class="flex flex-col">
    <div>
      {{ $t('calendar_advanced.extras.seats_per_slot') }}
      <div class="text-xs font-light leading-6 text-gray-600">
        {{ $t('calendar_advanced.extras.seats_per_slot_desc') }}
      </div>
    </div>
    <div class="flex items-center space-x-3 pb-3 pt-4">
      <div id="show-seats-per-slot">
        <UISwitch v-model:value="state.formData.showSeatsPerSlot"> </UISwitch>
      </div>

      <div class="font-normal">
        {{ $t('calendar_advanced.extras.show_seats_per_slot') }}
      </div>
    </div>
  </div>

  <div v-if="showStaffSelection" class="flex flex-col">
    <div>
      {{ $t('calendar_advanced.extras.show_staff_selection') }}
      <div class="text-xs font-light leading-6 text-gray-600">
        {{
          isServiceCalendar
            ? $t('calendar_advanced.extras.show_staff_selection_desc_service')
            : $t('calendar_advanced.extras.show_staff_selection_desc')
        }}
      </div>
    </div>
    <div class="flex items-center space-x-3 pb-3 pt-4">
      <div id="calendar-allow-staff-switch">
        <UISwitch
          v-model:value="state.formData.enableStaffSelection"
          :disabled="state.formData.widgetType === 'classic'"
        >
        </UISwitch>
      </div>
      <div class="font-normal">
        {{ $t('calendar_advanced.extras.allow_staff') }}
      </div>
    </div>
  </div>
</template>
