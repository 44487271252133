import AppState, { setShouldAllowPayment } from '@/states/app'

import Calendar from '@/models/calendar'
import { PaymentService } from '../class/services/PaymentService'
import { h } from 'vue'

export function isString(a: any) {
  if (typeof a === 'string' || a instanceof String) {
    return true
  }

  return false
}

export function isNumber(a: any) {
  if (typeof a === 'number' || a instanceof Number) {
    return true
  }

  return false
}

export function sortOptions<T extends Record<string, any>>(
  options: T[],
  key: keyof T
): T[] {
  if (!options || options.length < 1) {
    return []
  }

  return options.sort((first, second) => {
    if (!first[key] || !second[key]) {
      return 0
    }

    if (isString(first[key]) && isString(second[key])) {
      if (first[key].toLowerCase() < second[key].toLowerCase()) {
        return -1
      }
      if (first[key].toLowerCase() > second[key].toLowerCase()) {
        return 1
      }
    } else if (isNumber(first[key]) && isNumber(second[key])) {
      if (first[key] < second[key]) {
        return -1
      }
      if (first[key] > second[key]) {
        return 1
      }
    }

    return 0
  })
}

export const copyToClipboard = (text: string | undefined) => {
  if (!text) {
    return
  }
  // console.log('copyToClipboard', text)
  AppState.parentApp?.then(parent => {
    parent.emit('copy-to-clipboard', text)
  })
}

export const openInNewTab = (url: string) => {
  window.open(url, '_blank')?.focus()
}

export const shouldShowLegacyLink = (key: string, calendar: Calendar) => {
  return key !== 'copy_scheduling_link' || calendar.slug
}

const checkForValidPaymentProvider = (defaultPaymentProvider: any) => {
  if (!defaultPaymentProvider) {
    return false
  }
  switch (defaultPaymentProvider.provider) {
    case 'authorize-net':
      return !!(
        defaultPaymentProvider.providerConfig.live.merchantGatewayId ||
        defaultPaymentProvider.providerConfig.test.merchantGatewayId
      )
    case 'square':
    case 'nmi':
      return !!(
        defaultPaymentProvider.providerConfig.live.merchantGatewayId ||
        defaultPaymentProvider.providerConfig.test.merchantGatewayId
      )
    case 'stripe':
    case 'custom-provider':
      return !!(
        defaultPaymentProvider.providerConfig.live.publishableKey ||
        defaultPaymentProvider.providerConfig.test.publishableKey
      )
    default:
      return false
  }
}

export const isPaymentAllowed = async (locationId: string) => {
  const { data: paymentProvider } =
    await PaymentService.getDefaultPaymentProvider(locationId)

  const shouldAllowPayment = checkForValidPaymentProvider(
    paymentProvider.defaultPaymentProvider
  )
  setShouldAllowPayment(shouldAllowPayment)
}

export const generateSlug = () => {
  let slug = ''
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < 6; i++) {
    slug += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return slug
}

export const getTooltip = (text: string) => {
  const words = text.split(' ')
  const avgCharPx = 7
  const maxLineWidth = 270
  let lineWidth = 0
  const formattedText = words
    .map(word => {
      lineWidth += word.length * avgCharPx
      if (lineWidth >= maxLineWidth) {
        lineWidth = word.length * avgCharPx
        return '\n' + word
      }
      return word
    })
    .join(' ')

  const splitText = formattedText
    .split('\n')
    .map((line, index) => [index > 0 && h('br'), line])

  return {
    text: () => h('div', {}, splitText),
  }
}

export const getDefaultTeamMember = (userId: string) => {
  return {
    userId,
    priority: 0.5,
    isZoomAdded: 'false',
    zoomOauthId: '',
    meetingLocation: '',
    selected: true,
  }
}

export const getTimeSpanOptions = (
  maxTimeSpan: number,
  startTimeSpan: number,
  interval: number
) => {
  let currentSpan = startTimeSpan

  const _options = [
    {
      label: `${currentSpan} mins`,
      value: currentSpan,
    },
  ]
  while (currentSpan < maxTimeSpan) {
    currentSpan += interval || 0
    _options.push({
      label: `${currentSpan} mins`,
      value: currentSpan,
    })
  }

  return _options
}

export const wait = ms => new Promise(res => setTimeout(res, ms))

export const getInitials = (name: string) => {
  try {
    if (name.trim()) {
      const nameParts = name
        .trim()
        .split(' ')
        .filter(part => part !== '')
      const firstInitial = nameParts[0][0]?.toUpperCase()
      const secondInitial =
        nameParts.length > 1 ? nameParts[1][0]?.toUpperCase() : undefined

      if (secondInitial !== undefined) {
        return firstInitial + secondInitial
      }
      return firstInitial
    }
    return '-'
  } catch (error) {
    return '-'
  }
}
export function cleanHTMLForEmail(text: string) {
  let html = text
  html = html.replace(/<p(\s+[^>]*?)?>\s*(&nbsp;|\s)*<\/p>/g, '<br/>')

  // replace empty list items
  html = html.replace(/<li>(<br\/>)?<\/li>/g, '')

  return html
}

export function formatHTMLForTiptap(text: string) {
  let html = text
  html = html.replace(/<br\/>/gi, '<p style="margin: 0px;"></p>')
  html = html.replace(/\n/g, '')

  return html
}
export function isFileTypeSupported(type: string) {
  if (!type) {
    return true
  }
  if (
    type.includes('html') ||
    type.includes('php') ||
    type.includes('zip') ||
    type.includes('rar')
  ) {
    return false
  }
  return true
}
