<script setup lang="ts">
import AppState from '@/states/app'
import { computed, ref, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
const route = useRoute()

const tabType = ref(route.query.tab || 'my-connections')

const isUserPartOfLocation = computed(() => {
  return !!AppState?.isUserPartOfLocation
})

watchEffect(() => {
  if (!isUserPartOfLocation.value) {
    tabType.value = 'account-connections'
  }
})
</script>

<template>
  <div></div>
</template>
