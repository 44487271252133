import config from '@/config'
import axios from 'axios'
import { createPinia } from 'pinia'
import { createApp, watch } from 'vue'
import { createI18n } from 'vue-i18n'
import Toast from 'vue-toastification'
import App from './App.vue'
import { registerComponents } from './components/dynamic'
import './index.css'
// import '@gohighlevel/ghl-canvas/dist/ghl-canvas.css'
import da from '@/locales/da.json'
import de from '@/locales/de.json'
import en from '@/locales/en.json'
import es from '@/locales/es.json'
import fi from '@/locales/fi.json'
import fr_CA from '@/locales/fr_CA.json'
import fr_FR from '@/locales/fr_FR.json'
import it from '@/locales/it.json'
import nl from '@/locales/nl.json'
import no from '@/locales/no.json'
import pt_BR from '@/locales/pt_BR.json'
import pt_PT from '@/locales/pt_PT.json'
import sv from '@/locales/sv.json'
import router from '@/router'
import '@gohighlevel/ghl-ui/dist/style.css'
import 'vue-toastification/dist/index.css'
import AppState from './states/app'
import { setupSentry } from './util/setupSentry'

axios.defaults.baseURL = config.restApiUrl

axios.interceptors.request.use((config: any) => {
  config.headers['token-id'] = AppState.tokenId
  config.headers.channel = 'APP'
  config.headers.source = 'WEB_USER'
  config.headers.version = '2021-04-15'

  return config
})

axios.interceptors.response.use(
  function handleSuccess(res) {
    return res
  },
  async function handleError(error) {
    if (AppState.depth > 10) {
      AppState.depth = 0
      return Promise.reject(error)
    }

    if (error.config && error.response && error.response.status === 401) {
      if (AppState.parentApp) {
        AppState.parentApp.then((parent: any) => {
          parent.emit('getRefreshedTokenId')
        })
      }

      await wait(2 ** AppState.depth * 10) // exponential backoff time. -> wait for token
      AppState.depth++
      return axios.request(error.config)
    }

    return Promise.reject(error)
  }
)
const app = createApp(App)
app.use(createPinia())
app.use(router)

app.use(registerComponents)
app.use(Toast, {
  position: 'top-right',
  timeout: 2500,
  pauseOnFocusLoss: false,
})

app.mount('#app')
setupSentry(app, router, process.env.NODE_ENV as string)

watch(
  () => AppState.locale,
  () => {
    const i18n = createI18n({
      locale: AppState.locale || localStorage.getItem('locale') || 'en_US',
      globalInjection: true,
      legacy: false,
      fallbackLocale: 'en',
      runtimeOnly: true,
      messages: {
        en,
        es,
        nl,
        da,
        fr_CA,
        de,
        fi,
        fr_FR,
        it,
        no,
        pt_BR,
        pt_PT,
        sv,
      },
    })

    app.use(i18n)
  },
  {
    immediate: true,
  }
)

const wait = (ms: number) => new Promise(res => setTimeout(res, ms))
